import React from "react";
import { Nav } from "react-bootstrap";
import { BsList } from "react-icons/bs";
import { CgColorPicker } from "react-icons/cg";
import { AiOutlineQrcode } from "react-icons/ai";
import { SiSalesforce } from "react-icons/si";
import { LEFTPANEL_OPTIONS, BREAKPOINTS } from "../../utils/constans"
import { useViewport } from '../../utils/hooks';
import { ReactComponent as Astro } from '../../assets/icons/astro-head.svg';
import './LeftPanel.css'

function QRCodeLeftPanel(props) {

  const { width } = useViewport();

  // const manageActiveStep = (event) => {
  //   let element = event.target.parentNode.parentNode.parentNode.parentNode;
  //   let qrcodeOptions = document.getElementsByClassName("custom_nav-item");
  //   for (let i = 0; i < qrcodeOptions.length; i++) {
  //       qrcodeOptions[i].classList.remove("active");
  //   }
  //   if(width <= BREAKPOINTS.SMALL) collapseNavBar();
  //   element.classList.add("active");
  // }

  const collapseNavBar = (event) => {
    let navbar = document.getElementById("collapsable-nav-bar");
    navbar.classList.toggle("collapse");
  }

  return (
    <div className="top-bar">
      { width <= BREAKPOINTS.SMALL ?
      <div className="icon-container">
        <BsList size="40px" color="#A4A5A5" onClick={collapseNavBar}/>
      </div>
      : null }
      <div className="app-title">Salesforce QR Code Generator</div>
      <Nav variant="pills" className="flex-column" style={{width:"100%"}} id="collapsable-nav-bar">
        { LEFTPANEL_OPTIONS.map( (item) => (
          <Nav.Item key={item.name} className="custom_nav-item">
            <Nav.Link eventKey={item.name} className="custom_nav-link">
              {/* onClick={ manageActiveStep }> */}
              <div className="box">
                <div className={item.name === "background" ? "icon-container custom" : "icon-container"}>
                  { item.name === "qrcode" ? <AiOutlineQrcode className="icon"/> :
                      item.name === "colors" ? <CgColorPicker className="icon"/> :
                      item.name === "logo" ? <SiSalesforce className="icon salesforce-cloud"/> :
                      item.name === "background" ? <Astro className="icon astro"/> :
                      null
                  }
                </div>
                <div className="text">
                  {item.text}
                </div>
              </div>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  
  );
}

export default QRCodeLeftPanel;

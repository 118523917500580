const QR_CODE_MAX_SIZE = 640;
const QR_CODE_LOGO_RATIO = 0.3;
const LOGO_MAX_SIZE = QR_CODE_MAX_SIZE * QR_CODE_LOGO_RATIO;
const QR_CODE_INITIAL_FG_COLOR = { hex:"#000000FF" };
const QR_CODE_INITIAL_BG_COLOR = { hex:"#FFFFFF00" };
const QRCODE_STYLE_DOTS = "/images/app/style_dots.png";
const QRCODE_STYLE_SQUARES = "/images/app/style_squares.png";

const BREAKPOINTS = {
    SMALL: 768,
    LG: 1025,
    XLG: 1200,
}

const BACKGROUND_ARRAY = [
    { name: "muley1", source: "/images/scenes/template-1.png", qrCodePositionX: 640, qrCodePositionY: 890, widthQRCode: 552, heightQRCode: 552 },
    // Need kind of permissions from salesforce to add einstein images
    // { name: "einstein1", source: "/images/scenes/template-3.png", qrCodePositionX: 1575, qrCodePositionY: 2090, widthQRCode: 774, heightQRCode: 774 },
    { name: "astro1", source: "/images/scenes/astro-scene_1.png", qrCodePositionX: 1410, qrCodePositionY: 1800, widthQRCode: 684, heightQRCode: 684 },
    { name: "astro2", source: "/images/scenes/astro-scene_2.png", qrCodePositionX: 126, qrCodePositionY: 350, widthQRCode: 150, heightQRCode: 150 },
    { name: "astro3", source: "/images/scenes/astro-scene_3.png", qrCodePositionX: 480, qrCodePositionY: 315, widthQRCode: 216, heightQRCode: 216 },
    { name: "astro4", source: "/images/scenes/astro-scene_4.jpeg", qrCodePositionX: 172, qrCodePositionY: 703, widthQRCode: 354, heightQRCode: 354 },
    { name: "astro5", source: "/images/scenes/astro-scene_5.jpeg", qrCodePositionX: 731, qrCodePositionY: 692, widthQRCode: 354, heightQRCode: 354 },
    // { name: "einstein2", source: "/images/scenes/einstein-scene_1.png", qrCodePositionX: 472, qrCodePositionY: 170, widthQRCode: 168, heightQRCode: 168 },
]

const LOGO_ARRAY = [
    { name: "Salesforce", source: "/images/logos/salesforce.png", naturalWidth: 728, naturalHeight: 512},
    { name: "Salesforce-background", source: "/icons/salesforce-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Sales", source: "/icons/sales.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Sales-background", source: "/icons/sales-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Service", source: "/icons/service.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Service-background", source: "/icons/service-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Marketing", source: "/icons/marketing.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Marketing-background", source: "/icons/marketing-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Commerce", source: "/icons/commerce.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Commerce-background", source: "/icons/commerce-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Analytics", source: "/icons/analytics.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Analytics-background", source: "/icons/analytics-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Integration", source: "/icons/integration.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Integration-background", source: "/icons/integration-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Platform", source: "/icons/platform.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Platform-background", source: "/icons/platform-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Industries", source: "/icons/industries.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Industries-background", source: "/icons/industries-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Learning", source: "/icons/learning.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Learning-background", source: "/icons/learning-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Employees", source: "/icons/employees.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Employees-background", source: "/icons/employees-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Partners", source: "/icons/partners.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Partners-background", source: "/icons/partners-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Success", source: "/icons/success.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Success-background", source: "/icons/success-background.svg", naturalWidth: 200, naturalHeight: 200},
    // { name: "Einstein", source: "/icons/einstein.svg", naturalWidth: 200, naturalHeight: 200},
    // { name: "Einstein-background", source: "/icons/einstein-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Small_business", source: "/icons/small_business.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Small_business-background", source: "/icons/small_business-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Philanthropy", source: "/icons/philanthropy.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Philanthropy-background", source: "/icons/philanthropy-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Slack", source: "/icons/slack.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Slack-background", source: "/icons/slack-background.svg", naturalWidth: 200, naturalHeight: 200},
    { name: "Heroku-background", source: "/icons/heroku-background.svg", naturalWidth: 200, naturalHeight: 200},
    
]

const LEFTPANEL_OPTIONS = [
    { name: "qrcode", text: "Set up your QR Code"},
    { name: "colors", text: "Customize colors"},
    { name: "logo", text: "Select your logo"},
    { name: "background", text: "Select your scenes"},
]


const PRESET_FOREGROUND_COLORS = [
    { name: "BlueFG", hexA:"#009fe4FF", rgb: {r: 0, g: 159, b: 228, a: 1} },
    { name: "LightBlueFG", hexA:"#00ddffFF", rgb: {r: 0, g: 221, b: 255, a: 1} },
]

const PRESET_BACKGROUND_COLORS = [
    { name: "BlueBG", hexA:"#009fe4FF", rgb: {r: 0, g: 159, b: 228, a: 1} },
    { name: "LightBlueBG", hexA:"#00ddffFF", rgb: {r: 0, g: 221, b: 255, a: 1} },
]

const WHEEL_COLORS = [
    { 
        name: "SalesforceBlue",
        color: { hex:"#0d9dda" },
        secondary: [
            { name: "Blue", color: { hex:"#0d9dda" } },
            { name: "Blue1", color: { hex:"#032d60" } },
            { name: "Blue2", color: { hex:"#0b5cab" } },
            { name: "Blue3", color: { hex:"#0176d3" } },
            { name: "Blue4", color: { hex:"#90d0fe" } },
            { name: "Blue5", color: { hex:"#cfe9fe" } },
            { name: "Blue6", color: { hex:"#eaf5fe" } },
        ],
        products: [
            { name: "success", icon: "/icons/success-background.svg"},
            { name: "integration", icon: "/icons/integration-background.svg"},
        ]
    },
    { 
        name: "Service", 
        color: { hex:"#8a033e" },
        secondary: [ 
            { name: "Service1", color: { hex:"#8a033e" } },
            { name: "Service2", color: { hex:"#e26e8c" } },
            { name: "Service3", color: { hex:"#eebdc6" } },
            { name: "Service4", color: { hex:"#531a2b" } },
        ],
        products: [
            { name: "service", icon: "/icons/service-background.svg"},
        ]
    },
    { 
        name: "Employees", 
        color: { hex:"#d83a00" },
        secondary: [ 
            { name: "Employees1", color: { hex:"#d83a00" } },
            { name: "Employees2", color: { hex:"#ff784f" } },
            { name: "Employees3", color: { hex:"#feb9a5" } },
            { name: "Employees4", color: { hex:"#4a2413" } },
        ],
        products: [
            { name: "employees", icon: "/icons/employees-background.svg"},
        ]
    },
    { 
        name: "Marketing", 
        color: { hex:"#dd7a01" },
        secondary: [ 
            { name: "Marketing1", color: { hex:"#dd7a01" } },
            { name: "Marketing2", color: { hex:"#fe9339" } },
            { name: "Marketing3", color: { hex:"#ffba90" } },
            { name: "Marketing4", color: { hex:"#825101" } },
        ],
        products: [
            { name: "marketing", icon: "/icons/marketing-background.svg"},
        ]
    },
    { 
        name: "Partners", 
        color: { hex:"#e4a201" },
        secondary: [ 
            { name: "Partners1", color: { hex:"#e4a201" } },
            { name: "Partners2", color: { hex:"#fcc003" } },
            { name: "Partners3", color: { hex:"#f9e3b6" } },
            { name: "Partners4", color: { hex:"#a86403" } },
        ],
        products: [
            { name: "partners", icon: "/icons/partners-background.svg"},
            { name: "analytics", icon: "/icons/analytics-background.svg"},
        ]
    },
    { 
        name: "Sales", 
        color: { hex:"#0b827c" },
        secondary: [ 
            { name: "Sales1", color: { hex:"#0b827c" } },
            { name: "Sales2", color: { hex:"#04e1cb" } },
            { name: "Sales3", color: { hex:"#acf3e4" } },
            { name: "Sales4", color: { hex:"#024d4c" } },
        ],
        products: [
            { name: "sales", icon: "/icons/sales-background.svg"},
        ]
    },
    { 
        name: "Commerce", 
        color: { hex:"#396547" },
        secondary: [ 
            { name: "Commerce1", color: { hex:"#396547" } },
            { name: "Commerce2", color: { hex:"#70bf75" } },
            { name: "Commerce3", color: { hex:"#cdefc4" } },
            { name: "Commerce4", color: { hex:"#1c3326" } },
        ],
        products: [
            { name: "commerce", icon: "/icons/commerce-background.svg"},
        ]
    },
    { 
        name: "Industries", 
        color: { hex:"#481a54" },
        secondary: [ 
            { name: "Industries1", color: { hex:"#481a54" } },
            { name: "Industries2", color: { hex:"#730394" } },
            { name: "Industries3", color: { hex:"#8c5ec6" } },
            { name: "Industries4", color: { hex:"#2e0039" } },
        ],
        products: [
            { name: "industries", icon: "/icons/industries-background.svg"},
            { name: "learning", icon: "/icons/learning-background.svg"},
        ]
    },
    { 
        name: "Platform", 
        color: { hex:"#321d71" },
        secondary: [ 
            { name: "PLatform1", color: { hex:"#321d71" } },
            { name: "PLatform2", color: { hex:"#8a8ed1" } },
            { name: "PLatform3", color: { hex:"#c2c8e8" } },
            { name: "PLatform4", color: { hex:"#200d38" } },
        ],
        products: [
            { name: "platform", icon: "/icons/platform-background.svg"},
        ]
    },
    { 
        name: "SalesforceNeutral", 
        color: { hex:"#59575c" },
        secondary: [ 
            { name: "Neutral", color: { hex:"#59575c" } },
            { name: "Neutral1", color: { hex:"#9a8f87" } },
            { name: "Neutral2", color: { hex:"#c1b9b4" } },
            { name: "Neutral3", color: { hex:"#ded6cf" } },
            { name: "Neutral4", color: { hex:"#f5f2f2" } },
        ],
        products: []
    },
    { 
        name: "WheelBlack",
        color: { hex:"#000000" },
        secondary: [],
        products: []
    },
    { 
        name: "WheelWhite", 
        color: { hex:"#ffffff" },
        secondary: [],
        products: []
    },
    { 
        name: "WheelTransparent", 
        color: { hex:"#00000000" },
        secondary: [],
        products: []
    },
]
export { QR_CODE_MAX_SIZE, QR_CODE_INITIAL_FG_COLOR, QR_CODE_INITIAL_BG_COLOR, BACKGROUND_ARRAY, LOGO_ARRAY, LEFTPANEL_OPTIONS, PRESET_FOREGROUND_COLORS, PRESET_BACKGROUND_COLORS, QRCODE_STYLE_DOTS, QRCODE_STYLE_SQUARES, WHEEL_COLORS, BREAKPOINTS, LOGO_MAX_SIZE }
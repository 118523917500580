import React from "react";
import { WHEEL_COLORS } from '../../../../utils/constans';
import { Tabs, Tab } from "react-bootstrap";
import ColorPicker from "./ColorPicker";
import { graphImage } from "../../../../utils/functions";
import './Colors.css';

const TabColors = (props) => {
    
    const [ fgWheelColor, setFgWheelColor] = React.useState(WHEEL_COLORS[10]);
    const [ bgWheelColor, setBgWheelColor] = React.useState(WHEEL_COLORS[12]);

    const clickFgWheelColor = (item) => {
        setFgWheelColor(item)
        if(item.secondary.length === 0){
            props.setStateValue("fgColor",item.color)
        }else{
            props.setStateValue("fgColor",item.secondary[0].color);
        }
    }

    const clickBgWheelColor = (item) => {
        setBgWheelColor(item)
        if(item.secondary.length === 0){
            props.setStateValue("bgColor",item.color)
        }else{
            props.setStateValue("bgColor",item.secondary[0].color);
        }
    }
    return (
        <Tabs defaultActiveKey="foreground" id="uncontrolled-tab-example">
            <Tab eventKey="foreground" title="Foreground" className="custom_tab-pane">
                <div className="c-section">
                    <div className="section">
                        <div className="color_wheel">
                            { 
                                WHEEL_COLORS.map( (item, index) => (
                                <div key={item.name} className={ fgWheelColor.name === item.name ? "circle active num"+(index+1) : "circle num"+(index+1)}>
                                    <div className="color" style={{backgroundColor: item.color.hex}} onClick={() => clickFgWheelColor(item)}></div>
                                </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="section l_border">
                        <div className="product-icons">
                            { fgWheelColor.products.map ( item => (
                                <div key={item.name} className="icon-container">
                                    <img width="50" src={graphImage(item.icon)} alt={item.name}/>
                                </div>
                            ))}
                        </div>
                        <div className="detailed-colors">
                            { fgWheelColor.secondary.map ( item => (
                                <div key={item.name} className={ item.color.hex === props.fgColor.hex ? "circle-color active" : "circle-color"}>
                                    <div className="secondary-color" style={{backgroundColor: item.color.hex}} onClick={ () => props.setStateValue("fgColor",item.color)}></div>
                                </div>
                            ))}
                        </div>
                        <div className="custom-color">
                            <ColorPicker name="Foreground" handleChangeColor={(color) => props.setStateValue("fgColor",color)} color={props.fgColor.hex}/>
                        </div>
                    </div>
                </div>
            </Tab>
            <Tab eventKey="background" title="Background" className="custom_tab-pane">
                <div className="c-section">
                    <div className="section">
                        <div className="color_wheel">
                            { 
                                WHEEL_COLORS.map( (item, index) => (
                                <div key={item.name} className={ bgWheelColor.name === item.name ? "circle active num"+(index+1) : "circle num"+(index+1)}>
                                    <div className="color" style={{backgroundColor: item.color.hex}} onClick={() => clickBgWheelColor(item)}></div>
                                </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="section l_border">
                        <div className="product-icons">
                            { bgWheelColor.products.map ( item => (
                                <div key={item.name} className="icon-container">
                                    <img width="50" src={graphImage(item.icon)} alt={item.name}/>
                                </div>
                            ))}
                        </div>
                        <div className="detailed-colors">
                            { bgWheelColor.secondary.map ( item => (
                                <div key={item.name} className={ item.color.hex === props.bgColor.hex ? "circle-color active" : "circle-color"}>
                                    <div className="secondary-color" style={{backgroundColor: item.color.hex}} onClick={ () => props.setStateValue("bgColor",item.color)}></div>
                                </div>
                                
                            ))}
                        </div>
                        <div className="custom-color">
                            <ColorPicker name="Background" handleChangeColor={(color) => props.setStateValue("bgColor",color)} color={props.bgColor.hex}/>
                        </div>
                    </div>
                </div>
            </Tab>
        </Tabs>
        
    )
}

export default TabColors;
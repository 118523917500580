import React from "react";
import { Tab } from "react-bootstrap";
import QRSetup from './Tabs/QRSetup/QRSetup';
import Colors from './Tabs/Colors/Colors';
import Logos from './Tabs/Logos/Logos';
import Scenes from './Tabs/Scenes/Scenes';
import './Content.css'

const QRCodeOptionsArea = (props) => {
  // console.log("OPTIONS AREA PROPS",props);
  const { setStateValue, qrCodeUrl, pixelType, fgColor, bgColor, logo, backgroundArray } = props;

  const tab1Props = { qrCodeUrl, pixelType, setStateValue }
  const tab2Props = { fgColor, bgColor, setStateValue }
  const tab3Props = { logo, setStateValue }
  const tab4Props = { backgroundArray, setStateValue }

  // const imagesxd = require.context('../assets/images', true);
  // const xd = "/logos/salesforce.png";
  // const graphImage = (src) => (
  //   require('../assets/images' + src).default
  // )
  // console.log("XD", xd);
  // console.log("XD2",graphImage(xd));
  // console.log("test1", imagesxd);
  // console.log("test2",imagesxd(xdxdxd))

  return (
    <Tab.Content className="tab_content-custom">
      <Tab.Pane eventKey="qrcode" className="stretch">
        <QRSetup {...tab1Props} />
      </Tab.Pane>
      <Tab.Pane eventKey="colors" className="stretch">
        <Colors {...tab2Props} />
      </Tab.Pane>
      <Tab.Pane eventKey="logo" className="scrollable">
        <Logos {...tab3Props}/>
      </Tab.Pane>
      <Tab.Pane eventKey="background" className="scrollable">
        {/* unmountOnExit={true} */}
        <Scenes {...tab4Props}/>
      </Tab.Pane>
    </Tab.Content>
  );
}

export default QRCodeOptionsArea;

import React from "react";
import salesforceLogo from '../../assets/images/logos/salesforce.png'
// import { QRCode } from 'react-qrcode-logo';
import { QRCode } from '../../qrcode/index';
import { BACKGROUND_ARRAY} from '../../utils/constans';
import { GoTriangleLeft, GoTriangleRight } from 'react-icons/go'
import './Output.css';
var zip = require("jszip")();

function QRCodeOuput(props) {

  const [ templatePage, setTemplatePage] = React.useState(0);
  const [ carruselWidth, setCarruselWidth] = React.useState(0)

  React.useEffect( () => {
    drawScenes()
    configCarruselWidth()
  },[props.backgroundArray,props.value, props.bgColor, props.fgColor, props.logoImage, props.qrStyle]);

  const drawScenes = async () => {
      //WAIT UNTIL QRCODE CANVAS IS DRAWN
      await new Promise(resolve => setTimeout(resolve, 200));
      for(let i=0 ; i < props.backgroundArray.length; i++){
        let image = document.getElementById(`background-${props.backgroundArray[i]}`);
        let background_item = BACKGROUND_ARRAY.find( (item) => item.name === props.backgroundArray[i]);
        let originalWidth = image.naturalWidth;
        let originalHeight = image.naturalHeight;
        let background = document.getElementById(`template-${props.backgroundArray[i]}`);
        var destCtx = background.getContext('2d');
        background.width  = originalWidth;
        background.height = originalHeight;
        //Draw template
        destCtx.drawImage(image, 0, 0);
        let qrCodeCanvas = document.getElementById("react-qrcode-logo");
        //Draw QRCode
        destCtx.drawImage(qrCodeCanvas, background_item.qrCodePositionX, background_item.qrCodePositionY,background_item.widthQRCode,background_item.heightQRCode);
      }
      // Fix Bug when deselecting the last scenes when seeing that scene.
      if(templatePage !== 0 && templatePage >= props.backgroundArray.length) setTemplatePage(templatePage - 1)
  }

  const exportZip = () => {

    //Add QRCODE to zip 
    let qrCodeCanvas = document.getElementById("react-qrcode-logo");
    // canvas.toDataURL("image/png")returns   =>  data:image/png;base64,ASDNIGSDNFASDFASD...
    let base64Canvas = qrCodeCanvas.toDataURL("image/png").split(';base64,')[1];
    zip.file("qrcode.png", base64Canvas, {base64: true});
    
    // ADD Templates to zip
    for(let i=0 ; i < props.backgroundArray.length; i++){
      let templateCanvas = document.getElementById(`template-${props.backgroundArray[i]}`);
      let base64Png = templateCanvas.toDataURL("image/png").split(';base64,')[1];
      zip.file(`${props.backgroundArray[i]}.png`, base64Png, {base64: true});
    }

    zip.generateAsync({type:"blob"})
    .then(function(content) {
        // see FileSaver.js
        // saveAs(content, "example.zip");

        // Create a temporary a tag to download the image
        const a = document.createElement('a');
        // console.log("JIIJ",URL.createObjectURL(content));
        a.href = URL.createObjectURL(content);
        a.download = "salesforceqr.zip";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    });
    // zip = new JSZip();
    // zip.file("Hello.txt", "Hello World\n");
    // var img = zip.folder("images");
    // img.file("smile.gif", imgData, {base64: true});
  }

  const addPage = () => {
    setTemplatePage(templatePage + 1)
  }

  const removePage = () => {
    setTemplatePage(templatePage - 1)
  }

  const configCarruselWidth = async() => {
    //WAIT UNTIL QRCODE CANVAS IS DRAWN
    await new Promise(resolve => setTimeout(resolve, 200));
    const currentTemplate = document.getElementsByClassName("canvas-template");
    if (currentTemplate.length === 0 || !currentTemplate[templatePage] ) {
      return 0;
    }
    let width = currentTemplate[templatePage].clientWidth;
    setCarruselWidth(width);
  }

  const getCanvasContainerStyle = (index) => {
    const visibility = (templatePage === index) ? "visible" : "hidden";
    const opacity = (templatePage === index) ? 1 : 0;
    const transform = `translate( ${100 * (index - templatePage)}%, 0%)`;
    return {visibility, opacity, transform};
  }

  return (
    <div className="output">
        <p className="title">Preview</p>
        <div className="canvas-area" id="canvas-area">
          <div className="qrcode-container">
            <div className="canvas-container">
                {/* QRCode props size, value, bgcolor,fgColor,logoImage,qrStyle,logoWidth,logoHeight */}
                <QRCode {...props} quietZone={40}/>
            </div>
          </div>
          { props.backgroundArray.length !== 0 ?
          <div className="c-carrusel">
            <div className="carrusel" style={{width: carruselWidth}}>

            {props.backgroundArray.map( (item,index) => (
                  <div key={item} className="canvas-template" style={getCanvasContainerStyle(index)}>
                      <canvas width="0" height="0" id={`template-${item}`} style={{height:"100%"}}/>
                  </div>
            ))}
            { templatePage !== 0 ?
                <GoTriangleLeft size={30} className="previous-arrow" onClick={removePage}/>
            : null
            }
            { props.backgroundArray.length && (templatePage + 1) !== props.backgroundArray.length ?
                <GoTriangleRight size={30} className="next-arrow" onClick={addPage}/>
            : null
            }
            { props.backgroundArray.length > 1 ?
                <div className="dots-container">
                    {props.backgroundArray.map( (item,index) => (
                        <div key={item+index} className={ templatePage === index ? "dot active" : "dot"}></div>
                    ))}
                </div>
            : null
            }
            </div>
          </div>
          : null }
        </div>
        <button className="export-button" onClick={exportZip}>Download</button>
        <img className="logo" width="70" src={salesforceLogo} alt="SalesforceLogo"/>
    </div>
  );
}

export default QRCodeOuput;

import React from 'react';
import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Tab } from 'react-bootstrap';
import Output from './components/OutputArea/Output';
import Content from './components/ContentArea/Content';
import LeftPanel from './components/LeftPanel/LeftPanel';
import { QR_CODE_MAX_SIZE, QR_CODE_INITIAL_FG_COLOR, QR_CODE_INITIAL_BG_COLOR, BREAKPOINTS }  from './utils/constans';
import { useViewport } from './utils/hooks';

function App() {

  const initialState = {
    qrCodeUrl: "https://www.salesforce.com",
    backgroundArray: [],
    pixelType: "squares",
    fgColor: QR_CODE_INITIAL_FG_COLOR,
    bgColor: QR_CODE_INITIAL_BG_COLOR,
    logo: { name: "no-logo", width: "", height: ""}
  };
  
  const [state, setState] = React.useState(initialState);
  
  // Function to update state triggered from any child component
  const setStateValue = (stateKey, value) => {
    setState((prevState) => ({ ...prevState, [stateKey]: value }));
  };

  const { width } = useViewport();
  
  // Remove inline styles of qrcode generated by library and add ours
  React.useEffect( () => {
    const qrcode = document.getElementById("react-qrcode-logo")
    qrcode.removeAttribute("style")
    qrcode.style.display = "block";
    // Chess background like png with css
    qrcode.style.background = "conic-gradient(#fff 0.25turn, #EFEFEF 0.25turn 0.5turn, #fff 0.5turn 0.75turn, #EFEFEF 0.75turn) top left / 10% 10% repeat";
  },[])
  
  return (
    <div className={width <= BREAKPOINTS.SMALL ? "App mobile" : "App"}>
      <Tab.Container id="left-tabs-example" defaultActiveKey="qrcode">
        <div className="main">
          <LeftPanel />
        </div>
        <div className="qrcode-area" id="qrcode">
          <Content
            {...state}
            setStateValue={setStateValue}/>
          <Output size={QR_CODE_MAX_SIZE}
                value={state.qrCodeUrl}
                bgColor={state.bgColor.hex}
                fgColor={state.fgColor.hex}
                logoImage={state.logo.logo}
                qrStyle={state.pixelType}
                logoWidth={state.logo.width}
                logoHeight={state.logo.height}
                backgroundArray={state.backgroundArray}/>
        </div>
      </Tab.Container>
    </div>
  );
}

export default App;

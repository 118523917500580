import React from "react";
import { LOGO_ARRAY } from '../../../../utils/constans';
import { LOGO_MAX_SIZE } from "../../../../utils/constans"
import NoLogoIcon from "../../../../assets/icons/no_logo-icon.svg"
import { OverlayTrigger, Tooltip} from "react-bootstrap";
import { graphImage } from "../../../../utils/functions"
import './Logos.css';

const TabLogos = (props) => {

    const LogoItem = ({logo}) => {

        const handleClickLogo = (item) => {
            const [width,height] = scaleSize(LOGO_MAX_SIZE,LOGO_MAX_SIZE,item.naturalWidth,item.naturalHeight);
            props.setStateValue("logo",{name: item.name,logo: graphImage(item.source), width, height});
        }
        
        const scaleSize = (maxW, maxH, currW, currH) =>{
            var ratio = currH / currW;
            if(currW >= maxW){
                  currW = maxW;
                  currH = currW * ratio;
            } else if(currH >= maxH){
                  currH = maxH;
                  currW = currH / ratio;
            }
            return [currW, currH];
        }
    
        return (
            <div className={ props.logo.name === logo.name ? "item active" : "item"} onClick={ () => handleClickLogo(logo)}>
                <div className="bezel">
                    <OverlayTrigger
                        key="top"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-top`}>
                                {logo.name}
                            </Tooltip>
                        }
                        >
                        <img src={graphImage(logo.source)} className="image" alt={logo.name}/>
                    </OverlayTrigger>
                </div>
            </div>
        );
      }

    return (
        <div>
            <p className="logo-title">Logo options</p>
            <div className="logo-grid">
                <div className={ props.logo.name === "no-logo" ? "item active" : "item"} onClick={ () => props.setStateValue("logo",{name:"no-logo"})}>
                    <div className="bezel no-logo">
                        <OverlayTrigger
                            key="top"
                            placement="top"
                            overlay={
                                <Tooltip id={`tooltip-top`}>
                                    No-logo
                                </Tooltip>
                            }
                            >
                            <img src={NoLogoIcon} alt="No logo"/>
                        </OverlayTrigger>
                    </div>
                </div>
                { LOGO_ARRAY.map( (item) => (
                    <LogoItem key={item.source} logo={item}/>
                ))}
            </div>
        </div>
    )
}

export default TabLogos;
import React from 'react';
// import { SketchPicker } from 'react-color';
import { ChromePicker } from 'react-color';
import './ColorPicker.css';

function ColorPicker(props) {

  // const  presetColors = [
  //   { color: '#00ddff', title: 'React' },
  // ]

  const [show, setShow] = React.useState(false);

  const handleClick = () => {
    setShow(!show)
  };

  const handleClose = () => {
    setShow(false)
  };
  
  const handleChange = (color) => {
    //convert rgba to hex, QRCodeLibrary need color in hex 
    if(color.rgb.a !== 1){
      let decimal = Math.round(color.rgb.a * 255);
      let hexString = decimal.toString(16).length === 1 ? `0${decimal.toString(16)}` : decimal.toString(16);
      color.hex = color.hex.concat(hexString);
    }
    props.handleChangeColor(color)
  };

  const handleInputChange = (event) => {
    let color = { hex: event.target.value};
    props.handleChangeColor(color);
  }

  return (
    <div className="c-color-picker">
      <input onChange={ handleInputChange } className="color-picker-input" type="text" value={props.color}/>
      <div className="color-picker" onClick={ handleClick }>
            <div className="color" style={{backgroundColor: props.color}}></div>
            <div className="triangle"></div>
      </div>
      { show ? 
        <div className="popover">
          <div className="cover" onClick={ handleClose }/>
          {/* <SketchPicker color={props.color} onChange={handleChange} presetColors={presetColors}/> */}
          {/* presetColors={presetColors} */}
          <ChromePicker color={props.color} onChange={ handleChange }/>
        </div> 
      : null }
    </div>
  );
}

export default ColorPicker;

import React from "react";
import { QRCODE_STYLE_DOTS, QRCODE_STYLE_SQUARES } from '../../../../utils/constans';
import { graphImage } from "../../../../utils/functions";
import './QRSetup.css';

const TabUrlAndPatter = (props) => {
    
    const handleChangeQRCodeUrl = (event) => {
        props.setStateValue( "qrCodeUrl", event.target.value);
    }

    const handleChangePixelType = (event) => {
        props.setStateValue( "pixelType", event.target.value);
    }

    return (
        <div>

            <input type="text" id="qrcode-url" value={props.qrCodeUrl} 
                className="qrcode-input" 
                placeholder="QRCode Url" 
                autoComplete="off"
                onChange={handleChangeQRCodeUrl} />
            <div className="c-pattern">
                <label className={ props.pixelType === "squares" ? "pattern selected" : "pattern" }>
                    <img className="image" src={graphImage(QRCODE_STYLE_SQUARES)} alt="Pattern squares" width="180"/>
                    <input type="radio" checked={ props.pixelType === "squares" ? "checked" : "" }name="radio"  value="squares" onChange={handleChangePixelType}/>
                </label>
                <label className={ props.pixelType === "dots" ? "pattern selected" : "pattern" }>
                    <img className="image" src={graphImage(QRCODE_STYLE_DOTS)} alt="Pattern dots" width="180"/>
                    <input type="radio" checked={ props.pixelType === "dots" ? "checked" : "" }  name="radio" value="dots" onChange={handleChangePixelType}/>
                </label>
            </div>
        </div>
    )
}

export default TabUrlAndPatter;
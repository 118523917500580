import React from "react";
import { BACKGROUND_ARRAY } from '../../../../utils/constans';
import { graphImage } from "../../../../utils/functions"
import './Scenes.css';

const TabColors = (props) => {

    const [ multipleSelection, setMultipleSelection] = React.useState(false);

    const BackgroundItem = ({background}) => {

        const multiselectBackground = (event) => {
        //   console.log("CHECK CLICKED");
          let previousMultiselect = multipleSelection;
          setMultipleSelection(true);
          let newBackgroundArray = [...props.backgroundArray];
          let index = newBackgroundArray.indexOf(background.name);
          if( !previousMultiselect && index !== -1){
            newBackgroundArray.push(background.name)
          }
        //   console.log("SELCTE ABCKGROUNDS", newBackgroundArray, index);
          if(index === -1){
            newBackgroundArray.push(background.name)
          }else{
            newBackgroundArray.splice(index,1);
          }
        //   console.log("NUEVO SELECTED BACKGROUNDS",newBackgroundArray);
          if(newBackgroundArray.length === 0){
            setMultipleSelection(false);
          }
          props.setStateValue("backgroundArray",newBackgroundArray);
    
          event.stopPropagation();
        }
    
        const handleTemplate = (event) => {
          let name = background.name;
          if(multipleSelection){
            let newBackgroundArray = [...props.backgroundArray];
            let index = newBackgroundArray.indexOf(background.name);
            // console.log("SELCTE ABCKGROUNDS", newBackgroundArray, index);
            if(index === -1){
              newBackgroundArray.push(background.name)
            }else{
              newBackgroundArray.splice(index,1);
            }
            // console.log("NUEVO SELECTED BACKGROUNDS");
            props.setStateValue("backgroundArray",newBackgroundArray);
          }else{
            // let selectedBackgrounds = props.backgroundArray;
            let selectedBackgrounds = [name];
            // console.log("PASS Bacround array",selectedBackgrounds);
            props.setStateValue("backgroundArray",selectedBackgrounds);
          }
        }
        
        return (
            <div className={ props.backgroundArray.includes(background.name) ? "item active" : "item"} onClick={handleTemplate}>
                <img src={graphImage(background.source)} className="image" alt={background.name} id={"background-"+background.name}/>
                <div className="checkbox" onClick={multiselectBackground}>
                <div className="check"></div>
                </div>
            </div>
        );
      }

    return (
        <div>
            <p className="background-title">Scenes options</p>
            <div className={ multipleSelection ? "background-grid multiselect" : "background-grid"}>
            { BACKGROUND_ARRAY.map( (item) => (
                <BackgroundItem key={item.name} background={item} backgroundArray={props.backgroundArray} />
            ))}
            </div>
        </div>
    )
}

export default TabColors;